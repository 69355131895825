<template>
  <div class="terminal-data">
    <div class="title">内容喜好分析</div>
    <div class="chat-content">
      <el-row>
        <el-col :span="24">
          <div class="chat-item">
            <p class="chat-title">GV视频热度排行</p>
            <div class="search-form">
              <div>
                <span class="form-label">排序方式</span>
                <el-select v-model="gvPlaySortvalue" placeholder="请选择"
                           style="width: 130px; margin-right: 50px">
                  <el-option
                      v-for="item in sortOptions"
                      :key="item.value"
                      :label="item.label"
                      :value="item.value">
                  </el-option>
                </el-select>
                <span class="form-label">查询日期</span>
                <el-select v-model="playselect" placeholder="请选择"
                           style="width: 130px; margin-right: 10px">
                  <el-option
                      v-for="item in options"
                      :key="item.value"
                      :label="item.label"
                      :value="item.value">
                  </el-option>
                </el-select>
                <el-date-picker
                    v-if="playselect === '1'"
                    style="width: 150px; margin-left: 10px"
                    v-model="playDate"
                    :type="datePickerType"
                    :picker-options="pickerOptions"
                    value-format="yyyy-MM-dd"
                    placeholder="选择日期">
                </el-date-picker>
              </div>
              <div>
                <el-button type="primary" @click="gvCurrentChange(1)">查询</el-button>
              </div>
            </div>
            <div class="chat-box">
              <el-table :data="gvPlayTable" border empty-text="暂无统计数据" max-height="530px">
                <el-table-column label="序号" width="80" align="center">
                  <template slot-scope="scope">
                    <span>{{ (gvpage - 1) * gvlimit + scope.$index + 1 }}</span>
                  </template>
                </el-table-column>
                <el-table-column prop="videoID" label="视频ID" align="center"></el-table-column>
                <el-table-column prop="ContenName" label="视频名称" align="center"></el-table-column>
                <el-table-column prop="browseNum" label="浏览量" align="center"></el-table-column>
                <el-table-column prop="playNum" label="播放量" align="center"></el-table-column>
                <el-table-column prop="zan" label="点赞量" align="center"></el-table-column>
                <el-table-column prop="postComment" label="评论量" align="center"></el-table-column>
                <el-table-column prop="collect" label="收藏数" align="center"></el-table-column>
                <el-table-column prop="cai" label="点踩数" align="center"></el-table-column>
                <el-table-column prop="shareVideo" label="分享数" align="center"></el-table-column>
                <el-table-column prop="downloadVideo" label="下载数" align="center"></el-table-column>
                <el-table-column prop="videoDuration" label="视频时长" align="center"
                                 :formatter="row=>formatTime(row.videoDuration)"></el-table-column>
                <el-table-column prop="playSumDuration" label="平均播放时长" align="center"
                                 :formatter="row=>formatTime(row.playSumDuration)"></el-table-column>
                <el-table-column prop="completionRate" label="完播率" align="center"
                                 :formatter="row=>`${(row.completionRate*100).toFixed(2)}%`"></el-table-column>
              </el-table>
            </div>
            <div class="pagination">
              <el-pagination
                  background
                  layout="total, prev, pager, next"
                  :total="gvplayCount"
                  :page-size="gvlimit"
                  :current-page.sync="gvpage"
                  @current-change="gvCurrentChange"
              >
              </el-pagination>
            </div>
          </div>
        </el-col>
      </el-row>
      <el-row>
        <el-col :span="24">
          <div class="chat-item">
            <p class="chat-title">电影热度排行</p>
            <div class="search-form">
              <div>
                <span class="form-label">排序方式</span>
                <el-select v-model="mvPlaySortvalue" placeholder="请选择"
                           style="width: 130px; margin-right: 50px">
                  <el-option
                      v-for="item in sortOptions"
                      :key="item.value"
                      :label="item.label"
                      :value="item.value">
                  </el-option>
                </el-select>
                <span class="form-label">查询日期</span>
                <el-select v-model="mvPlayselect" placeholder="请选择"
                           style="width: 130px; margin-right: 10px">
                  <el-option
                      v-for="item in options"
                      :key="item.value"
                      :label="item.label"
                      :value="item.value">
                  </el-option>
                </el-select>
                <el-date-picker
                    style="width: 150px; margin-left: 10px"
                    v-if="mvPlayselect === '1'"
                    v-model="mvPlayDate"
                    :type="datePickerType"
                    :picker-options="pickerOptions"
                    value-format="yyyy-MM-dd"
                    placeholder="选择日期">
                </el-date-picker>
              </div>
              <div>
                <el-button type="primary" @click="mvCurrentChange(1)">查询</el-button>
              </div>
            </div>
            <div class="chat-box">
              <el-table :data="mvPlayTable" border empty-text="暂无统计数据" max-height="530px">
                <el-table-column label="序号" width="100" align="center">
                  <template slot-scope="scope">
                    <span>{{ (mvpage - 1) * mvlimit + scope.$index + 1 }}</span>
                  </template>
                </el-table-column>
                <el-table-column prop="videoID" label="视频ID" align="center"></el-table-column>
                <el-table-column prop="ContenName" label="视频名称" align="center"></el-table-column>
                <el-table-column prop="browseNum" label="浏览量" align="center"></el-table-column>
                <el-table-column prop="playNum" label="播放量" align="center"></el-table-column>
                <el-table-column prop="zan" label="点赞量" align="center"></el-table-column>
                <el-table-column prop="postComment" label="评论量" align="center"></el-table-column>
                <el-table-column prop="collect" label="收藏数" align="center"></el-table-column>
                <el-table-column prop="cai" label="点踩数" align="center"></el-table-column>
                <el-table-column prop="shareVideo" label="分享数" align="center"></el-table-column>
                <el-table-column prop="videoDuration" label="视频时长" align="center"
                                 :formatter="row=>formatTime(row.videoDuration)"></el-table-column>
                <el-table-column prop="playSumDuration" label="平均播放时长" align="center"
                                 :formatter="row=>formatTime(row.playSumDuration)"></el-table-column>
                <el-table-column prop="completionRate" label="完播率" align="center"
                                 :formatter="row=>`${(row.completionRate*100).toFixed(2)}%`"></el-table-column>

              </el-table>
            </div>
            <div class="pagination">
              <el-pagination
                  background
                  layout="total, prev, pager, next"
                  :total="mvplayCount"
                  :page-size="mvlimit"
                  :current-page.sync="mvpage"
                  @current-change="mvCurrentChange"
              >
              </el-pagination>
            </div>
          </div>
        </el-col>
      </el-row>
      <el-row>
        <el-col :span="24">
          <div class="chat-item">
            <p class="chat-title">电视热度排行</p>
            <div class="search-form">
              <div>
                <span class="form-label">排序方式</span>
                <el-select v-model="tvPlaySortvalue" placeholder="请选择"
                           style="width: 130px; margin-right: 50px">
                  <el-option
                      v-for="item in tvSortOptions"
                      :key="item.value"
                      :label="item.label"
                      :value="item.value">
                  </el-option>
                </el-select>
                <span class="form-label">查询日期</span>
                <el-select v-model="tvPlayselect" placeholder="请选择"
                           style="width: 130px; margin-right: 10px">
                  <el-option
                      v-for="item in options"
                      :key="item.value"
                      :label="item.label"
                      :value="item.value">
                  </el-option>
                </el-select>
                <el-date-picker
                    style="width: 150px; margin-left: 10px"
                    v-if="tvPlayselect === '1'"
                    v-model="tvPlayDate"
                    :type="datePickerType"
                    :picker-options="pickerOptions"
                    value-format="yyyy-MM-dd"
                    placeholder="选择日期">
                </el-date-picker>
              </div>
              <div>
                <el-button type="primary" @click="tvCurrentChange(1)">查询</el-button>
              </div>
            </div>
            <div class="chat-box">
              <el-table :data="tvPlayTable" border empty-text="暂无统计数据" max-height="530px">
                <el-table-column label="序号" width="100" align="center">
                  <template slot-scope="scope">
                    <span>{{ (tvpage - 1) * tvlimit + scope.$index + 1 }}</span>
                  </template>
                </el-table-column>
                <el-table-column prop="videoID" label="视频ID" align="center"></el-table-column>
                <el-table-column prop="ContenName" label="视频名称" align="center"></el-table-column>
                <el-table-column prop="browseNum" label="浏览量" align="center"></el-table-column>
                <el-table-column prop="playNum" label="播放量" align="center"></el-table-column>
                <el-table-column prop="zan" label="点赞量" align="center"></el-table-column>
                <el-table-column prop="postComment" label="评论量" align="center"></el-table-column>
                <el-table-column prop="collect" label="收藏数" align="center"></el-table-column>
                <el-table-column prop="cai" label="点踩数" align="center"></el-table-column>
                <el-table-column prop="shareVideo" label="分享数" align="center"></el-table-column>
              </el-table>
            </div>
            <div class="pagination">
              <el-pagination
                  background
                  layout="total, prev, pager, next"
                  :total="tvplayCount"
                  :page-size="tvlimit"
                  @current-change="tvCurrentChange"
              >
              </el-pagination>
            </div>
          </div>
        </el-col>
      </el-row>
      <el-row>
        <el-col :span="24">
          <div class="chat-item">
            <p class="chat-title">写真热度排行</p>
            <div class="search-form">
              <div>
                <span class="form-label">排序方式</span>
                <el-select v-model="picPlaySortvalue" placeholder="请选择"
                           style="width: 130px; margin-right: 50px">
                  <el-option
                      v-for="item in imgSortOptions"
                      :key="item.value"
                      :label="item.label"
                      :value="item.value">
                  </el-option>
                </el-select>
                <span class="form-label">查询日期</span>
                <el-select v-model="picPlayselect" placeholder="请选择"
                           style="width: 130px; margin-right: 10px">
                  <el-option
                      v-for="item in options"
                      :key="item.value"
                      :label="item.label"
                      :value="item.value">
                  </el-option>
                </el-select>
                <el-date-picker
                    style="width: 150px; margin-left: 10px"
                    v-if="picPlayselect === '1'"
                    v-model="picPlayDate"
                    :type="datePickerType"
                    :picker-options="pickerOptions"
                    value-format="yyyy-MM-dd"
                    placeholder="选择日期">
                </el-date-picker>
              </div>
              <div>
                <el-button type="primary" @click="picCurrentChange(1)">查询</el-button>
              </div>
            </div>
            <div class="chat-box">
              <el-table :data="picPlayTable" border empty-text="暂无统计数据" max-height="530px">
                <el-table-column label="序号" width="100" align="center">
                  <template slot-scope="scope">
                    <span>{{ (picpage - 1) * piclimit + scope.$index + 1 }}</span>
                  </template>
                </el-table-column>
                <el-table-column prop="videoID" label="写真ID" align="center"></el-table-column>
                <el-table-column prop="ContenName" label="写真名称" align="center"></el-table-column>
                <el-table-column prop="browseNum" label="浏览量" align="center"></el-table-column>
                <el-table-column prop="postComment" label="评论量" align="center"></el-table-column>
                <el-table-column prop="collect" label="收藏数" align="center"></el-table-column>
              </el-table>
            </div>
            <div class="pagination">
              <el-pagination
                  background
                  layout="total, prev, pager, next"
                  :total="picplayCount"
                  :page-size="piclimit"
                  :current-page.sync="picpage"
                  @current-change="picCurrentChange"
              >
              </el-pagination>
            </div>
          </div>
        </el-col>
      </el-row>
      <el-row :gutter="10">
        <el-col :span="12">
          <div class="chat-item">
            <p class="chat-title">各类型内容分类浏览次数</p>
            <div class="search-form">
              <div>
                <span class="form-label">类型</span>
                <el-select v-model="videoType" placeholder="请选择"
                           style="width: 80px; margin-right: 10px">
                  <el-option
                      v-for="item in videoTypeOptions"
                      :key="item.value"
                      :label="item.label"
                      :value="item.value">
                  </el-option>
                </el-select>
                <span class="form-label">查询日期</span>
                <el-select v-model="classPlayselect" placeholder="请选择"
                           style="width: 110px; margin-right: 10px">
                  <el-option
                      v-for="item in options"
                      :key="item.value"
                      :label="item.label"
                      :value="item.value">
                  </el-option>
                </el-select>
                <el-date-picker
                    style="width: 136px; margin-left: 10px"
                    v-if="classPlayselect === '1'"
                    v-model="classPlayDate"
                    :type="datePickerType"
                    value-format="yyyy-MM-dd"
                    :picker-options="pickerOptions"
                    placeholder="选择日期">
                </el-date-picker>
              </div>
              <div>
                <el-button type="primary" @click="classCurrentChange(1)">查询</el-button>
              </div>
            </div>
            <div class="chat-box" style="display: flex; flex-direction: row">
              <el-table :data="classPlayTable" border empty-text="暂无统计数据" height="330px">
                <el-table-column label="序号" width="100" align="center">
                  <template slot-scope="scope">
                    <span>{{ (classpage - 1) * classlimit + scope.$index + 1 }}</span>
                  </template>
                </el-table-column>
                <el-table-column prop="categryId" label="分类ID" align="center"></el-table-column>
                <el-table-column prop="categryName" label="分类名称" align="center"></el-table-column>
                <el-table-column prop="count" label="观看量" align="center"></el-table-column>
              </el-table>
            </div>
            <div class="pagination">
              <el-pagination
                  background
                  layout="total, prev, pager, next"
                  :total="classplayCount"
                  :page-size="classlimit"
                  @current-change="classCurrentChange"
              >
              </el-pagination>
            </div>
          </div>
        </el-col>
        <el-col :span="12">
          <div class="chat-item">
            <p class="chat-title">用户搜索词排行</p>
            <div class="search-form">
              <div>
                <span class="form-label">查询日期</span>
                <el-select v-model="searchResultPlayselect" placeholder="请选择"
                           style="width: 130px; margin-right: 10px">
                  <el-option
                      v-for="item in options"
                      :key="item.value"
                      :label="item.label"
                      :value="item.value">
                  </el-option>
                </el-select>
                <el-date-picker
                    style="width: 150px; margin-left: 10px"
                    v-if="searchResultPlayselect === '1'"
                    v-model="searchResultPlayDate"
                    :type="datePickerType"
                    value-format="yyyy-MM-dd"
                    :picker-options="pickerOptions"
                    placeholder="选择日期">
                </el-date-picker>
              </div>
              <div>
                <el-button type="primary" @click="searchResultCurrentChange(1)">查询</el-button>
              </div>
            </div>
            <div class="chat-box">
              <el-table :data="searchResultPlayTable" border empty-text="暂无统计数据" height="330px">
                <el-table-column label="序号" width="100" align="center">
                  <template slot-scope="scope">
                    <span>{{ (searchResultpage - 1) * searchResultlimit + scope.$index + 1 }}</span>
                  </template>
                </el-table-column>
                <el-table-column prop="searchText" label="关键词" align="center"></el-table-column>
                <el-table-column prop="count" label="搜索次数" align="center"></el-table-column>
              </el-table>
            </div>
            <div class="pagination">
              <el-pagination
                  background
                  layout="total, prev, pager, next"
                  :total="searchResultplayCount"
                  :page-size="searchResultlimit"
                  :current-page.sync="searchResultpage"
                  @current-change="searchResultCurrentChange"
              >
              </el-pagination>
            </div>
          </div>
        </el-col>
      </el-row>
      <el-row :gutter="10">
        <el-col :span="12">
          <div class="chat-item">
            <p class="chat-title">各类型内容浏览次数</p>
            <div class="search-form">
              <div>
                <span class="form-label">查询日期</span>
                <el-select v-model="viewNumDatevalue" placeholder="请选择" style="width: 130px">
                  <el-option
                      v-for="item in options"
                      :key="item.value"
                      :label="item.label"
                      :value="item.value">
                  </el-option>
                </el-select>
                <el-date-picker
                    style="width: 150px; margin-left: 10px"
                    v-if="viewNumDatevalue === '1'"
                    v-model="viewNumDay"
                    type="date"
                    :picker-options="pickerOptions"
                    value-format="yyyy-MM-dd"
                    placeholder="选择日期">
                </el-date-picker>
              </div>
              <div>
                <el-button type="primary" @click="getChart1Data">查询</el-button>
              </div>
            </div>
            <div class="chat-box" ref="chat1"></div>
          </div>
        </el-col>
        <el-col :span="12">
          <div class="chat-item">
            <p class="chat-title">各渠道分享次数</p>
            <div class="search-form">
              <div>
                <span class="form-label">查询日期</span>
                <el-select v-model="shareVideoDatevalue" placeholder="请选择" style="width: 130px">
                  <el-option
                      v-for="item in options"
                      :key="item.value"
                      :label="item.label"
                      :value="item.value">
                  </el-option>
                </el-select>
                <el-date-picker
                    style="width: 150px; margin-left: 10px"
                    v-if="shareVideoDatevalue === '1'"
                    v-model="shareVideoDay"
                    type="date"
                    :picker-options="pickerOptions"
                    value-format="yyyy-MM-dd"
                    placeholder="选择日期">
                </el-date-picker>
              </div>
              <div>
                <el-button type="primary" @click="getChart2Data">查询</el-button>
              </div>
            </div>
            <div class="chat-box" ref="chat2"></div>
          </div>
        </el-col>
      </el-row>
    </div>
  </div>
</template>

<script>
import {searchResult, shareVideo, videoClassify, videoHotCount, viewsNum} from "../../api/chart";
import {formatTime} from "@/utils/func"

export default {
  name: "terminalData",
  data() {
    return {
      playDate: new Date(),
      mvPlayDate: new Date(),
      tvPlayDate: new Date(),
      picPlayDate: new Date(),
      classPlayDate: new Date(),
      searchResultPlayDate: new Date(),
      sortOptions: [
        {value: "1", label: "按播放量"},
        {value: "9", label: "按浏览量"},
        {value: "2", label: "按点赞量"},
        {value: "3", label: "按评论量"},
        {value: "4", label: "按收藏量"},
        {value: "5", label: "按点踩量"},
        {value: "6", label: "按分享量"},
        {value: "7", label: "按下载量"},
        {value: "8", label: "按完播率"},
      ],
      // gv
      gvPlaySortvalue: '1',
      playselect: "2",
      gvPlayTable: [],
      gvpage: 1,
      gvlimit: 20,
      gvplayCount: 0,
      // mv
      mvPlaySortvalue: '1',
      mvPlayselect: "2",
      mvPlayTable: [],
      mvpage: 1,
      mvlimit: 20,
      mvplayCount: 0,
      // tv
      tvPlaySortvalue: '1',
      tvPlayselect: "2",
      tvPlayTable: [],
      tvpage: 1,
      tvlimit: 20,
      tvplayCount: 0,
      // pic
      picPlaySortvalue: '3',
      picPlayselect: "2",
      picPlayTable: [],
      picpage: 1,
      piclimit: 20,
      picplayCount: 0,
      // class
      classPlayselect: "2",
      classPlayTable: [],
      classpage: 1,
      classlimit: 10,
      classplayCount: 0,
      //searchResul
      searchResultPlayselect: "2",
      searchResultPlayTable: [],
      searchResultpage: 1,
      searchResultlimit: 10,
      searchResultplayCount: 0,
      // viewNum
      viewNumDatevalue: '2',
      viewNumDay: new Date(),
      // shareVideo
      shareVideoDatevalue: '2',
      shareVideoDay: new Date(),
      tvSortOptions: [
        {value: "1", label: "按播放量"},
        {value: "9", label: "按浏览量"},
        {value: "2", label: "按点赞量"},
        {value: "3", label: "按评论量"},
        {value: "4", label: "按收藏量"},
        {value: "5", label: "按点踩量"},
        {value: "6", label: "按分享量"},
      ],
      imgSortOptions: [
        {value: "3", label: "按评论量"},
        {value: "4", label: "按收藏量"},
        {value: "9", label: "按浏览量"},
      ],
      options: [{
        value: '1',
        label: '按日查询'
      }, {
        value: '2',
        label: '近7天'
      }, {
        value: '3',
        label: '近15天'
      }, {
        value: '4',
        label: '近30天'
      },
        {
          value: '5',
          label: '近1年'
        }],

      apiSelectvalue: "1",
      pickerOptions: {
        disabledDate(time) {
          return time.getTime() > Date.now();
        }
      },
      videoTypeOptions: [
        {
          value: '1',
          label: 'GV'
        },
        {
          value: '2',
          label: '电影'
        },
        {
          value: '3',
          label: '电视'
        },
        {
          value: '4',
          label: '写真'
        }
      ],
      videoType: '1',
      myChart1: null,
      myChart2: null,
    }
  },
  computed: {
    datePickerType() {
      switch (this.playselect) {
        case "1":
          return 'date'
        default:
          return 'date'
      }
    },
    orderDatePickerType() {
      switch (this.apiSelectvalue) {
        case "1":
          return 'date'
        case "2":
          return 'week'
        case "3":
          return 'month'
        case "4":
          return 'year'
        default:
          return 'date'
      }
    }
  },
  mounted() {
    this.getTable1Data()
    this.getMvTableData()
    this.getTvTableData()
    this.getPicTableData()
    this.getVideoChassData()
    this.getSearchResultData()
    this.getChart1Data()
    this.getChart2Data()
    this.initChart1()
    this.initChart2()
  },
  methods: {
    searchResult,
    formatTime,
    gvCurrentChange(page) {
      this.gvpage = page
      this.getTable1Data()
    },
    async getTable1Data() {
      try {
        const queryParams = {
          dateType: this.playselect,
          date: this.playDate,
          sortType: this.gvPlaySortvalue,
          videoType: "gv",
          page: this.gvpage,
          limit: this.gvlimit
        }
        const r = await videoHotCount(queryParams)
        if (r.code === 0) {
          this.gvPlayTable = r.data
          this.gvplayCount = r.count
        }
      } catch (e) {
        console.log(e)
      }
    },
    mvCurrentChange(page) {
      this.mvpage = page
      this.getMvTableData()
    },
    async getMvTableData() {
      try {
        const queryParams = {
          dateType: this.mvPlayselect,
          date: this.mvPlayDate,
          sortType: this.mvPlaySortvalue,
          videoType: "mv",
          page: this.mvpage,
          limit: this.mvlimit
        }
        const r = await videoHotCount(queryParams)
        if (r.code === 0) {
          this.mvPlayTable = r.data
          this.mvplayCount = r.count
        }
      } catch (e) {
        console.log(e)
      }
    },
    tvCurrentChange(page) {
      this.tvpage = page
      this.getTvTableData()
    },
    async getTvTableData() {
      try {
        const queryParams = {
          dateType: this.tvPlayselect,
          date: this.tvPlayDate,
          sortType: this.tvPlaySortvalue,
          videoType: "tv",
          page: this.tvpage,
          limit: this.tvlimit
        }
        const r = await videoHotCount(queryParams)
        if (r.code === 0) {
          this.tvPlayTable = r.data
          this.tvplayCount = r.count
        }
      } catch (e) {
        console.log(e)
      }
    },
    picCurrentChange(page) {
      this.picpage = page
      this.getPicTableData()
    },
    async getPicTableData() {
      try {
        const queryParams = {
          dateType: this.picPlayselect,
          date: this.picPlayDate,
          sortType: this.picPlaySortvalue,
          videoType: "photo",
          page: this.picpage,
          limit: this.piclimit
        }
        const r = await videoHotCount(queryParams)
        if (r.code === 0) {
          this.picPlayTable = r.data
          this.picplayCount = r.count
        }
      } catch (e) {
        console.log(e)
      }
    },
    classCurrentChange(page) {
      this.classpage = page
      this.getVideoChassData()
    },
    async getVideoChassData() {
      try {
        const queryParams = {
          dateType: this.classPlayselect,
          date: this.classPlayDate,
          videoType: this.videoType,
          page: this.classpage,
          limit: this.classlimit
        }
        const r = await videoClassify(queryParams)
        if (r.code === 0) {
          this.classPlayTable = r.data
          this.classplayCount = r.count
        }
      } catch (e) {
        console.log(e)
      }
    },
    searchResultCurrentChange(page) {
      console.log(page)
      this.searchResultpage = page
      this.getSearchResultData()
    },
    async getSearchResultData() {
      try {
        const queryParams = {
          dateType: this.searchResultPlayselect,
          date: this.searchResultPlayDate,
          page: this.searchResultpage,
          limit: this.searchResultlimit
        }
        const r = await searchResult(queryParams)
        if (r.code === 0) {
          this.searchResultPlayTable = r.data
          this.searchResultplayCount = r.count
        }
      } catch (e) {
        console.log(e)
      }
    },
    async getChart1Data() {
      try {
        const queryParams = {
          dateType: this.viewNumDatevalue,
          date: this.viewNumDay
        }
        const r = await viewsNum(queryParams)
        if (r.code === 0) {
          const chartData = r.data
          const gv = chartData.find(item => item.typeText === 'gv')
          const tv = chartData.find(item => item.typeText === 'tv')
          const mv = chartData.find(item => item.typeText === 'mv')
          const photo = chartData.find(item => item.typeText === 'photo')
          this.myChart1.setOption({
            series: [
              {
                data: [
                  {value: gv && gv.count, name: 'GV'},
                  {value: mv && mv.count, name: '电影'},
                  {value: tv && tv.count, name: '电视'},
                  {value: photo && photo.count, name: '写真'}
                ],
              }
            ]
          })
        }
      } catch (e) {
        console.log(e)
      }
    },
    async getChart2Data() {
      try {
        const queryParams = {
          dateType: this.shareVideoDatevalue,
          date: this.shareVideoDay
        }
        const r = await shareVideo(queryParams)
        if (r.code === 0) {
          const chartData = r.data
          const gv = chartData.find(item => item.brandName === 'gv')
          const tv = chartData.find(item => item.brandName === 'tv')
          const mv = chartData.find(item => item.brandName === 'mv')
          const tuiguang = chartData.find(item => item.brandName === 'tuiguang')
          this.myChart2.setOption({
            series: [
              {
                data: [
                  {value: gv && gv.count, name: 'GV'},
                  {value: mv && mv.count, name: '电影'},
                  {value: tv && tv.count, name: '电视'},
                  {value: tuiguang && tuiguang.count, name: '推广赚钱'},
                ],
              }
            ]
          })
        }
      } catch (e) {
        console.log(e)
      }
    },
    initChart1() {
      // 基于准备好的dom，初始化echarts实例
      this.myChart1 = this.$echarts.init(this.$refs.chat1);
      // 绘制图表
      this.myChart1.setOption({
        toolbox: {
          show: true,
          feature: {
            mark: {show: true},
            saveAsImage: {show: true}
          }
        },
        tooltip: {
          trigger: 'item'
        },
        legend: {
          orient: 'horizontal',
          bottom: '20',
        },
        series: [
          {
            name: '浏览次数',
            type: 'pie',
            radius: '50%',
            data: [],
            label: {
              formatter: '{b}:{c}次\n占比{d}%',
            },
            emphasis: {
              itemStyle: {
                shadowBlur: 10,
                shadowOffsetX: 0,
                shadowColor: 'rgba(0, 0, 0, 0.5)'
              }
            }
          }
        ]
      });
    },
    initChart2() {
      // 基于准备好的dom，初始化echarts实例
      this.myChart2 = this.$echarts.init(this.$refs.chat2);
      // 绘制图表
      this.myChart2.setOption({
        toolbox: {
          show: true,
          feature: {
            mark: {show: true},
            saveAsImage: {show: true}
          }
        },
        tooltip: {
          trigger: 'item'
        },
        legend: {
          orient: 'horizontal',
          bottom: '20',
        },
        series: [
          {
            name: '分享次数',
            type: 'pie',
            radius: '50%',
            data: [],
            label: {
              formatter: '{b}:{c}次\n占比{d}%',
            },
            emphasis: {
              itemStyle: {
                shadowBlur: 10,
                shadowOffsetX: 0,
                shadowColor: 'rgba(0, 0, 0, 0.5)'
              }
            }
          }
        ]
      });
    },
  }
}
</script>

<style lang="scss" scoped>
.title {
  font-size: 1.6rem;
  color: #333;
  padding-bottom: 10px;
  font-weight: bold;
  padding-left: 1.4rem;
}

.chat-content {
  box-sizing: border-box;
}

.chat-item {
  background: #fff;
  border-radius: 6px;
  padding: 20px;
  margin-bottom: .7rem;
  position: relative;
}

.chat-title {
  font-size: 1.29rem;
  color: #333333;
  margin-bottom: 1.4rem;
}

.chat-box {
  background: #f0eeee;
  min-height: 26rem;
}

.pagination {
  display: flex;
  justify-content: flex-end;
  padding-top: 10px;
}

.totle-data {
  position: absolute;
  top: 160px;
  left: 0;
  z-index: 999;
  padding: 0 40px;
  box-sizing: border-box;
  display: flex;
  width: 100%;
  flex-direction: row;

  div {
    flex: 1;

    span {
      margin-right: 20px;
    }
  }

  span {
    color: #333;
  }
}

.search-form {
  display: flex;
  justify-content: space-between;
  margin-bottom: 1.4rem;

  .form-label {
    padding-right: 1rem;
  }
}
</style>
